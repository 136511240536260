<template>
  <HeaderBlock />
  <main class="bg-dark-900">
    <!-- Hero -->
    <section class="px-4 py-12 md:py-16 sm:px-6 lg:px-8">
      <div class="max-w-screen-xl mx-auto">
        <div class="grid lg:grid-cols-1 lg:gap-x-8 xl:gap-x-16">
          <div
            class="flex flex-col items-start justify-between sm:items-center lg:items-start"
          >
            <!-- Section header -->
            <div>
              <div class="w-full text-left sm:text-center lg:text-left"></div>
              <div
                class="w-full mt-4 text-left md:mt-5 sm:text-center lg:text-left"
              >
                <h1
                  class="text-4xl font-extrabold text-white sm:text-5xl md:text-6xl"
                >
                  Get in touch
                </h1>
                <p
                  class="max-w-lg mx-auto mt-3 text-xl md:mt-5 text-dark-300 sm:max-w-2xl"
                >
                  We look forward to partnering with you
                </p>
              </div>
            </div>

            <!-- Company locations -->
            <div class="grid gap-6 mt-8 sm:mt-10">
              <!-- Location 1 -->
              <div>
                <h5 class="text-lg font-bold text-white">Cyprus</h5>
                <p class="mt-1 text-base text-dark-300">
                  Andrea Paraskeva 44, 2024 Strovolos, Nicosia
                  <br />Reg nr.: C459306
                  <br />
                  info@reflink.pro
                </p>
              </div>
            </div>
          </div>

          <!-- Contact form card -->
          <div class="w-full mt-12">
            <div
              class="w-full px-4 py-12 mx-auto shadow-xl rounded-3xl lg:mr-0 lg:ml-auto bg-dark-700 sm:p-16 lg:p-14 xl:p-16"
            >
              <div>
                <label
                  for="name"
                  class="ml-0.5 text-dark-300 font-medium text-sm"
                >
                  Name *
                </label>
                <input
                  id="name"
                  type="text"
                  v-model="formData.name"
                  name="name"
                  placeholder="John Doe"
                  class="w-full p-4 mt-2 text-sm font-medium text-white transition duration-200 ease-in-out border-2 border-solid outline-none h-14 rounded-2xl bg-dark-800 border-dark-800 focus:border-dark-600 focus:outline-none"
                  required
                />
              </div>

              <div class="mt-6">
                <label
                  for="email"
                  class="ml-0.5 text-dark-300 font-medium text-sm"
                >
                  Email *
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  v-model="formData.email"
                  placeholder="john@email.com"
                  class="w-full p-4 mt-2 text-sm font-medium text-white transition duration-200 ease-in-out border-2 border-solid outline-none h-14 rounded-2xl bg-dark-800 border-dark-800 focus:border-dark-600 focus:outline-none"
                  required
                />
              </div>

              <div class="mt-6">
                <label
                  for="phone"
                  class="ml-0.5 text-dark-300 font-medium text-sm"
                >
                  Phone
                </label>
                <input
                  id="phone"
                  type="text"
                  v-model="formData.phone"
                  name="phone"
                  placeholder="(123) 456-789"
                  class="w-full p-4 mt-2 text-sm font-medium text-white transition duration-200 ease-in-out border-2 border-solid outline-none h-14 rounded-2xl bg-dark-800 border-dark-800 focus:border-dark-600 focus:outline-none"
                />
              </div>

              <div class="mt-6">
                <label
                  for="message"
                  class="ml-0.5 text-dark-300 font-medium text-sm"
                >
                  Message *
                </label>
                <textarea
                  id="message"
                  type="text"
                  name="message"
                  placeholder="Message"
                  v-model="formData.message"
                  rows="5"
                  class="w-full p-4 mt-2 text-sm font-medium text-white transition duration-200 ease-in-out border-2 border-solid outline-none rounded-2xl bg-dark-800 border-dark-800 focus:border-dark-600 focus:outline-none"
                  required
                ></textarea>
              </div>

              <div class="flex justify-start mt-6">
                <button
                  type="submit"
                  @click="sendForm"
                  class="flex items-center justify-center w-auto px-8 py-4 text-base font-semibold leading-snug transition ease-in-out bg-white rounded-full h-14 duration-250 text-dark-900 hover:text-white focus:outline-none hover:bg-dark-900"
                >
                  Send message
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <footer-block />
</template>
<script setup>
import { ref } from "vue";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import axios from "axios";

import { useToast } from "vue-toastification";
const toast = useToast();
// toast.success("Hello");
// toast.error("Hello");

const formData = ref({
  name: "",
  email: "",
  phone: "",
  message: "",
});

const responseMessage = ref("");

const sendForm = async () => {
  try {
    const response = await axios.post(
      "https://reflink.pro/email.php",
      formData.value
    );
    console.log(response);
    if (response.data.success === true) {
      toast.success("Email has been sent succesfully");
      formData.value = {
        name: "",
        email: "",
        phone: "",
        message: "",
      };
    } else {
      toast.error("Email didnt send. There was an error");
    }
  } catch (error) {
    responseMessage.value = "Сетевая ошибка. Попробуйте позже.";
  }
};
</script>
