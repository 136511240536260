<template>
  <footer class="pt-14 px-4 py-12 md:py-16 bg-dark-900 sm:px-6 lg:px-8">
    <div
      class="grid gap-8 mx-auto lg:max-w-screen-xl sm:max-w-3xl md:grid-cols-3 md:gap-y-12 lg:grid-cols-3"
    >
      <!-- Logo and copyright text -->
      <div class="flex flex-col lg:mx-auto">
        <div class="flex items-center">
          <router-link to="/">
            <img src="/logo.svg" />
          </router-link>
        </div>
        <div class="mt-6 text-lg md:mt-8 text-dark-300">
          &#169; 2024 RefLink LTD
          <br />
          All rights reserved.
        </div>
      </div>

      <!-- Contact information -->
      <div class="lg:mx-auto">
        <h6 class="text-xl font-semibold text-white">Get in touch</h6>
        <p class="mt-2 text-lg text-dark-300">
          Reg nr.: C459306 Address: Andrea Paraskeva 44, 2024 Strovolos,
          Nicosia, Cyprus.
          <br />
          info@reflink.pro
        </p>
        <br />
        <!--        <p class="text-lg text-dark-300">hello@crator.com</p>-->
      </div>

      <!-- Site links -->
      <div class="lg:mx-auto">
        <h6 class="text-xl font-semibold text-white">Documents</h6>
        <ul class="mt-2 space-y-1 text-lg">
          <li class="font-medium text-dark-300 hover:text-white">
            <a href="/terms.docx"> Terms & Conditions </a>
          </li>
          <li class="font-medium text-dark-300 hover:text-white">
            <a target="_blank" href="/privacy.docx"> Privacy Policy </a>
          </li>
          <li class="font-medium text-dark-300 hover:text-white">
            <a target="_blank" href="/cookie.docx"> Cookie Policy </a>
          </li>
          <li class="font-medium text-dark-300 hover:text-white">
            <a target="_blank" href="mailto:info@reflink.pro"> Contact us </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
