<template>
  <header class="bg-dark-900">
    <nav class="flex items-center px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="flex items-center justify-between w-full">
        <div class="flex items-center">
          <router-link
            to="/"
            class="block text-2xl font-black md:hidden lg:block group"
          >
            <img src="/logo.svg" />
          </router-link>
        </div>
        <div
          class="hidden md:flex justify-between items-center md:space-x-0.5 lg:space-x-2 text-xl md:text-base font-medium text-dark-300"
        >
          <router-link
            to="/"
            class="block px-4 py-1 transition duration-200 ease-in-out rounded-full sm:inline-block hover:text-white hover:bg-dark-700"
          >
            Home
          </router-link>
          <a
            href="#"
            @click="scrollToElements('#about')"
            class="block px-4 py-1 transition duration-200 ease-in-out rounded-full sm:inline-block hover:text-white hover:bg-dark-700"
          >
            About us
          </a>
          <a
            href="#"
            @click="scrollToElements('#solution')"
            class="block px-4 py-1 transition duration-200 ease-in-out rounded-full sm:inline-block hover:text-white hover:bg-dark-700"
          >
            Solutions
          </a>
          <a
            href="#"
            @click="scrollToElements('#advantage')"
            class="block px-4 py-1 transition duration-200 ease-in-out rounded-full sm:inline-block hover:text-white hover:bg-dark-700"
          >
            Advantages
          </a>
        </div>

        <div class="hidden md:block">
          <router-link
            class="flex items-center justify-center w-auto px-8 py-3 text-base font-semibold leading-snug transition ease-in-out bg-white rounded-full duration-250 text-dark-900 hover:text-white focus:outline-none hover:bg-dark-800"
            to="/contact-us"
          >
            Contact us
          </router-link>
        </div>

        <div class="block md:hidden">
          <button
            @click="open = !open"
            class="relative z-50 w-6 h-5 transition duration-500 ease-in-out transform rotate-0 cursor-pointer group focus:outline-none"
          >
            <span
              class="absolute block h-1 transition-all duration-300 ease-in-out transform rotate-0 rounded-full opacity-100 bg-dark-300 group-hover:bg-white"
              :class="open ? 'top-2 left-1/2 w-0' : 'w-full top-0 left-0'"
            ></span>
            <span
              class="absolute left-0 block w-full h-1 transition-all duration-300 ease-in-out transform rounded-full opacity-100 bg-dark-300 group-hover:bg-white top-2"
              :class="open ? 'rotate-45' : 'rotate-0'"
            ></span>
            <span
              class="absolute left-0 block w-full h-1 transition-all duration-300 ease-in-out transform rounded-full opacity-100 bg-dark-300 group-hover:bg-white top-2"
              :class="open ? '-rotate-45' : 'rotate-0'"
            ></span>
            <span
              class="absolute block h-1 transition-all duration-300 ease-in-out transform rotate-0 rounded-full opacity-100 bg-dark-300 group-hover:bg-white"
              :class="open ? 'top-2 left-1/2 w-0' : 'w-full left-0 top-4'"
            ></span>
          </button>

          <!-- Mobile menu -->
          <!--
                    Toggle classes based on menu state
                  -->
          <div
            class="absolute top-0 left-0 z-40 flex items-center justify-center w-screen h-screen bg-gradient-to-tr from-dark-800 to-dark-900"
            v-if="open"
          >
            <div
              class="flex flex-col items-center w-full p-4 mx-auto space-y-8 text-xl justify-evenly"
            >
              <router-link
                to="/"
                class="block px-6 py-2 font-medium transition duration-200 ease-in-out rounded-full text-dark-300 hover:text-white hover:bg-dark-700 sm:inline-block"
              >
                Home
              </router-link>
              <a
                href="#"
                @click="scrollToElements('#about')"
                class="block px-6 py-2 font-medium transition duration-200 ease-in-out rounded-full text-dark-300 hover:text-white hover:bg-dark-700 sm:inline-block"
              >
                About us
              </a>
              <a
                href="#"
                @click="scrollToElements('#solution')"
                class="block px-6 py-2 font-medium transition duration-200 ease-in-out rounded-full text-dark-300 hover:text-white hover:bg-dark-700 sm:inline-block"
              >
                Solutions
              </a>
              <a
                href="#"
                @click="scrollToElements('#advantage')"
                class="block px-6 py-2 font-medium transition duration-200 ease-in-out rounded-full text-dark-300 hover:text-white hover:bg-dark-700 sm:inline-block"
              >
                Advantages
              </a>
              <router-link
                class="flex items-center justify-center w-auto px-8 py-4 text-lg font-semibold leading-snug transition ease-in-out bg-white rounded-full duration-250 text-dark-900 hover:text-white focus:outline-none hover:bg-dark-700"
                to="/contact-us"
              >
                Let's talk
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>
<script setup>
import { ref } from "vue";
import { scrollTo } from "vue-scrollto";
import { useRouter } from "vue-router";
const router = useRouter();

const open = ref(false);

const scrollToElements = (el) => {
  router
    .push("/")
    .catch(() => {})
    .then(() => {
      open.value = false;
      scrollTo(el, 500, { offset: -100 });
    });
};
</script>
